













import { Component, Prop, Vue } from 'vue-property-decorator'

import ChartBase from '@/components/charts/ChartBase.vue'
import StatusIcon from '@/components/StatusIcon.vue'
import { getPercent } from '@/utils/functions'

@Component({
  components: {
    ChartBase,
    StatusIcon,
  },
})
export default class ChartMaster extends Vue {
  @Prop({ required: true })
  private total!: number

  @Prop({ required: true })
  private completed!: number

  @Prop({ default: 'teal' })
  private skin!: 'teal'|'blue'

  @Prop({ default: 'уроки' })
  private label!: string

  private get percentTotal () {
    return getPercent(this.total, this.completed)
  }

  private get isDone () {
    return this.percentTotal === 100
  }
}
