













































































import { Component, Mixins } from 'vue-property-decorator'

import ChartMaster from '@/components/charts/ChartMaster.vue'
import CureHeartModal from '@/components/modals/exercise/CureHeartModal.vue'
import TakeHeartModal from '@/components/modals/exercise/TakeHeartModal.vue'
import Hearts from '@/components/Hearts.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TransferMasterModal from '@/components/modals/masterGroups/TransferMasterModal.vue'
import UserCard from '@/components/cards/UserCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import ManagerUsersModule from '@/store/modules/manager/users'
import { Permission } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import { getCurrentMonth } from '@/utils/functions'
import { ICureAndTakeHeartForm } from '@/store/types/forms'

enum MenuActions {
  TRANSFER = 'transfer',
  CURE_HEART = 'cureHeart',
  TAKE_HEART = 'takeHeart',
}

@Component({
  components: {
    ChartMaster,
    CureHeartModal,
    Hearts,
    LinkTabs,
    Parameters,
    Tag,
    TakeHeartModal,
    TransferMasterModal,
    UserCard,
  },
})
export default class MastersItem extends Mixins(NotifyMixin, PermissionsMixin) {
  private isLoaded = false
  private showTransferModal = false
  private monthId: number | null = +this.$route.query.monthID || null
  private showActions = false

  private showModalCureHeart = false
  private showModalTakeHeart = false

  private filterMonthID = this.$route.query.monthID

  private get menu() {
    const list = []
    if (this.availableTransferAll || this.availableTransferLite) {
      list.push({ icon: '$signOut', iconColor: 'teal darken-4', name: 'Перевод из группы', value: MenuActions.TRANSFER })
    }
    if (this.hasPermission(this.Permission.MASTER_HEALING)) {
      list.push({ icon: '$heartPlus', iconColor: 'teal', name: 'Вернуть жизнь', value: MenuActions.CURE_HEART })
    }
    if (this.hasPermission(this.Permission.MASTER_HEALING)) {
      list.push({ icon: '$heartMinus', iconColor: 'orange', name: 'Снять жизнь', value: MenuActions.TAKE_HEART })
    }
    return list
  }

  // Текущий ли месяц?
  private get isCurrentMonth() {
    if (!this.monthId)
      return false
    return this.currentMonthId !== this.monthId
  }

  // id текущего месяца
  private get currentMonthId() {
    const foundMasterGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === +this.masterGroupID)
    if (foundMasterGroup) {
      return getCurrentMonth(foundMasterGroup?.months)?.value
    }
    return null
  }

  private get masterUUID () {
    return this.$route.params.masterUUID
  }

  private get masterGroupID () {
    return this.$route.query.groupID
  }

  private get breadcrumbs () {
    return [{ name: 'Мастера', path: '/manager/education/masters' }]
  }

  private get availableTransferAll () {
    return this.hasPermission(this.Permission.MASTER_TRANSFER_ALL)
  }

  private get availableTransferLite () {
    return this.hasPermission(this.Permission.MASTER_TRANSFER_LITE)
  }

  private get tabs () {
    const list = []
    if (this.hasPermission(Permission.MASTER_LESSONS_VIEW)) {
      list.push({
        name: 'Уроки',
        to: {
          name: 'manager.education.masters.item.lessons',
          query: { groupID: this.masterGroupID },
        },
      })
    }

    if (this.hasPermission(Permission.MASTER_EXERCISES_VIEW)) {
      list.push({
        name: 'Домашняя работа',
        to: {
          name: 'manager.education.masters.item.exercises',
          query: { groupID: this.masterGroupID },
        },
      })
    }

    if (this.hasPermission(Permission.MASTER_VIEW)) {
      list.push({
        name: 'Данные и курсы',
        to: {
          name: 'manager.education.masters.item.information',
          query: { groupID: this.masterGroupID },
        },
      }, {
        name: 'Заказы',
        to: {
          name: 'manager.education.masters.item.payments',
          query: { groupID: this.masterGroupID },
        },
      })
    }

    return list
  }

  private get master () {
    return ManagerGroupsModule.groupMaster
  }

  private created () {
    this.fetchUser()
    this.$bus.$on('updateCurrentMasterGroup', this.updateCurrentMasterGroup)
    this.$bus.$on('changeMonth', this.changeMonth)
  }

  private destroyed() {
    this.$bus.$off('updateCurrentMasterGroup', this.updateCurrentMasterGroup as any)
    this.$bus.$off('changeMonth', this.changeMonth as any)
  }

  private updateCurrentMasterGroup(id: number) {
    this.$router.push({ query: { groupID: id.toString() } })
  }

  private changeMonth(monthId: number) {
    ManagerGroupsModule.fetchGroupMaster({ masterGroupID: +this.masterGroupID, masterUUID: this.masterUUID, params: { monthId } })
      .then(() => {
        this.monthId = monthId
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.education.masters.list' })
      })
  }

  private redirect () {
    this.$router.push({ name: 'manager.education.masters.list' })
  }

  private fetchUser () {
    ManagerGroupsModule.fetchGroupMaster({ masterGroupID: +this.masterGroupID, masterUUID: this.masterUUID, params: { monthId: +this.filterMonthID || undefined } })
      .then(this.fetchUserMainData)
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.education.masters.list' })
      })
  }

  private fetchUserMainData () {
    ManagerUsersModule.fetchUser(this.masterUUID)
      .then(() => {
        this.isLoaded = true
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.education.masters.list' })
      })
  }

  private handleCure(form: ICureAndTakeHeartForm) {
    if (form.exerciseID && this.master) {
      const { user } = this.master
      ManagerGroupsModule.cureGroupMaster({
        body: {
          message: form.message,
        },
        exerciseID: form.exerciseID,
        masterGroupID: +this.masterGroupID,
        masterID: user.id,
      })
        .then(() => {
          this.notifySuccess(`Вы вернули жизнь мастеру ${user.name} ${user.surname}`)
          this.$bus.$emit('updateMasterExercises')
        })
        .catch(this.notifyError)
    }
  }

  private handleTake(form: ICureAndTakeHeartForm) {
    if (form.exerciseID && this.master) {
      const { user } = this.master
      ManagerGroupsModule.takeGroupMaster({
        body: {
          message: form.message,
        },
        exerciseID: form.exerciseID,
        masterGroupID: +this.masterGroupID,
        masterID: user.id,
      })
        .then(() => {
          this.notifySuccess(`Вы сняли жизнь мастеру ${user.name} ${user.surname}`)
          this.$bus.$emit('updateMasterExercises')
        })
        .catch(this.notifyError)
    }
  }

  private handleMenuItemClick (action: MenuActions) {
    switch(action) {
    case MenuActions.TRANSFER:
      this.showTransferModal = true
      break
    case MenuActions.CURE_HEART:
      this.showModalCureHeart = true
      break
    case MenuActions.TAKE_HEART:
      this.showModalTakeHeart = true
      break
    }
  }
}
